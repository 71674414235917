(function($, window, document, undefined) {
    const $cards = $('.team-card');
  
    function flipCard($card) {
      $card.addClass('flipped');
    }
  
    function unflipCard($card) {
      $card.removeClass('flipped');
    }
  
    $cards.each(function() {
      const $card = $(this);
      const $flipBtn = $card.find('.flip-card-btn');
      const $closeBtn = $card.find('.flip-card-close-btn');
  
      $flipBtn.on('click', (event) => {
        flipCard($card);
        event.preventDefault();
      });
  
      $closeBtn.on('click', (event) => {
        unflipCard($card);
        event.preventDefault();
      });
    });
  })(jQuery, window, document);
  