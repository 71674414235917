// Initialise Slick slider

(function ($, window, document, undefined) {

	// Uncomment to use SVG icon buttons
	const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" width="47.98" height="21.28" viewBox="0 0 47.98 21.28">
	<g id="arrow" transform="translate(47.98 21.28) rotate(180)">
	  <g id="Layer_1" data-name="Layer 1">
		<path id="Path_5080" data-name="Path 5080" d="M37.34,0,35.93,1.41l8.16,8.16H0v2H44.22l-8.29,8.3,1.41,1.41L47.98,10.64Z" fill="currentcolor"/>
	  </g>
	</g>
  </svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg id="arrow" xmlns="http://www.w3.org/2000/svg" width="47.98" height="21.28" viewBox="0 0 47.98 21.28">
	<g id="Layer_1" data-name="Layer 1">
	  <path id="Path_5080" data-name="Path 5080" d="M37.34,0,35.93,1.41l8.16,8.16H0v2H44.22l-8.29,8.3,1.41,1.41L47.98,10.64Z" fill="currentcolor"/>
	</g>
  </svg></button>`;
  const restartButtonHTML = `<button type="button" class="slick-arrow slick-next slick-restart button-rounded" hidden>
	<span><span class="slick-next-text">Restart?</span></span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.15 70.63"><path d="M64.73,21.08H8.82l17-17.26L22.23,0l-.45.47C0,23,0,23.39,0,23.71s0,.75,21.68,23l.42.44,3.82-3.55L8.82,26.33H64.73a19.53,19.53,0,0,1,0,39.05H39.9v5.25H64.73a24.78,24.78,0,0,0,0-49.55Z" fill="currentcolor"/></svg>
	</button>`;

	const $imageCarousel = $('.js-image-carousel');

	if ($imageCarousel.length) {

		$imageCarousel.slick({
			autoplay: false,
			dots: true,
			arrows: true,
			prevArrow: prevArrowHTML,
			nextArrow: nextArrowHTML,
			infinite: true,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '17%',
			responsive: [{
				breakpoint: 768,
				settings: {
					centerMode: false,
					centerPadding: 0,
					arrows: false,
					adaptiveHeight: true
				}
			}]
		});

	} // /$imageCarousel.length

	const $projectImageCarousel = $('.js-project-image-carousel');

	if ($projectImageCarousel.length) {

		$projectImageCarousel.slick({
			autoplay: false,
			dots: true,
			appendDots: '.project__image-carousel-dots',
			arrows: false,
			infinite: true,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: 0
		});

	} // /$projectImageCarousel.length

	const $testimonialCarousel = $('.js-testimonial-carousel');

	if ($testimonialCarousel.length) {

		$testimonialCarousel.slick({
			autoplay: false,
			dots: true,
			arrows: true,
			prevArrow: prevArrowHTML,
			nextArrow: nextArrowHTML,
			infinite: true,
			draggable: true,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '17%',
			responsive: [{
				breakpoint: 768,
				settings: {
					centerMode: false,
					centerPadding: 0,
					arrows: false
				}
			}]
		});

	} // /$testimonialCarousel.length


	const $stepsCarousel = $('.js-steps-carousel');

	if ($stepsCarousel.length) {

		// Function to create steps pagination
		function createStepsPagination(slick) {
			// Remove existing pagination to avoid duplication
			if ($('.steps-pagination__wrapper').length === 0) {
				const $paginationContainer = $('<div class="steps-pagination__wrapper"><div class="steps-pagination"></div></div>');
				const $paginationInner = $paginationContainer.find('.steps-pagination');
				const totalSlides = slick.$slides.length;
	
				slick.$slides.each(function (index) {
					const $titleElement = $(this).find('.steps-carousel__title');
					const stepNumberText = `Step ${index + 1} of ${totalSlides}`;
					const $paginationItem = $(`
						<button class="step-pagination-button" data-step="${index}">
							${stepNumberText}
						</button>
					`);
					$paginationInner.append($paginationItem);
				});
	
				$stepsCarousel.after($paginationContainer);
	
				// Add click event to pagination buttons
				$('.step-pagination-button').on('click', function () {
					const stepIndex = $(this).data('step');
					$stepsCarousel.slick('slickGoTo', stepIndex);
				});
			}
		}
	
		// Function to append restart button
		function appendRestartButton() {
			if (!$stepsCarousel.find('.slick-restart').length) {
				const $restartButton = $(restartButtonHTML);
				$stepsCarousel.append($restartButton);
	
				// Restart button click event
				$restartButton.on('click', function () {
					$stepsCarousel.slick('slickGoTo', 0);
				});
			}
		}
	
		// Initialize the slick carousel
		$stepsCarousel.slick({
			autoplay: false,
			dots: false, // Disable default dots
			arrows: true,
			prevArrow: `<button type="button" class="slick-prev button-rounded" aria-label="Previous step"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 21.28"><path d="M10.64,21.28l1.41-1.41L3.89,11.71,2.826,10.64l.934-.93,8.29-8.3L10.64,0,0,10.64Z" fill="currentcolor"/></svg></button>`,
			nextArrow: `<button type="button" class="slick-next button-rounded"><span><span class="slick-next-text">Next Step</span> <span class="slick-next-title">Legal Contract</span></span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 21.28"><path d="M1.41,0,0,1.41,8.16,9.57l1.064,1.07-.934.93L0,19.87l1.41,1.41L12.05,10.64Z" fill="currentcolor"/></svg></button>`,
			infinite: false,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			fade: true,
			responsive: [{
				breakpoint: 768,
				settings: {
					centerMode: false,
					centerPadding: 0,
					adaptiveHeight: true
				}
			}]
		});
	
		// Custom logic for steps pagination and restart button
		$stepsCarousel.on('init', function (event, slick) {
			createStepsPagination(slick);
			appendRestartButton();
			updatePagination(slick, 0); // Set initial state
		});
	
		// Update pagination and restart button on slide change
		$stepsCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			const $restartButton = $stepsCarousel.find('.slick-restart');
			const $nextSlide = slick.$slides.eq(nextSlide + 1);
			const $buttonTitle = slick.$slider.find('.slick-next-title');
	
			if ($nextSlide.length) {
				$buttonTitle.text($nextSlide.find('.steps-carousel__title').text());
			}
	
			if (nextSlide + 1 === slick.slideCount) {
				$restartButton.css('display', 'flex').removeAttr('hidden');
			} else {
				$restartButton.css('display', 'none').attr('hidden', true);
			}
	
			updatePagination(slick, nextSlide);
		});
	
		// Reinitialize pagination and restart button after resizing
		$stepsCarousel.on('setPosition', function (event, slick) {
			createStepsPagination(slick);
			appendRestartButton();
			const currentSlide = slick.currentSlide || 0;
			updatePagination(slick, currentSlide);
		});
	
		// Function to update the active state of pagination buttons
		function updatePagination(slick, currentSlide) {
			$('.step-pagination-button').removeClass('active');
			$(`.step-pagination-button[data-step="${currentSlide}"]`).addClass('active');
		}
	}


	const $newsCarousel = $('.js-news-carousel');

	if ($newsCarousel.length) {

		// Run on a specific selector with default args
		$newsCarousel.slick({
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: true,
			adaptiveHeight: true,
			draggable: true,
			slidesToShow: 1,
		});

	} // /$newsCarousel.length


	const $statsCarousel = $('.js-stats-carousel');

	if ($statsCarousel.length) {

		// Run on a specific selector with default args
		$statsCarousel.slick({
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: true,
			adaptiveHeight: true,
			draggable: true,
			slidesToShow: 1,
		});

	} // /$statsCarousel.length


	const $teamCarousel = $('.js-team-carousel');

	if ($teamCarousel.length) {

		// Run on a specific selector with default args
		$teamCarousel.slick({
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: false,
			adaptiveHeight: true,
			draggable: true,
			variableWidth: true,
			swipeToSlide: true
		});

	} // /$teamCarousel.length

})(jQuery, window, document);