(function () {
    let mobileTl;

    // Initialize animations
    function initAnimations() {
        init();
        animateElements();
        animateUnderline();
        animateElementsRight();
        animateElementsLeft();
        document.documentElement.classList.remove('no-gsap');
        document.documentElement.classList.add('gsap');
    }

    function init() {
        // Ensure ScrollTrigger is registered
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(CSSRulePlugin);
        // GSAP configuration
        gsap.config({
            nullTargetWarn: false,
            force3D: false,
        });
        gsap.defaults({
            ease: "ease",
            duration: 0.75,
        });

        // ScrollTrigger configuration
        ScrollTrigger.config({
            limitCallbacks: true,
        });

        ScrollTrigger.defaults({
            once: true,
        });

        // Refresh ScrollTrigger points for Safari
        setTimeout(function () {
            ScrollTrigger.refresh();
        }, 1000);

        let shapesTimeline = gsap.timeline();
    }

    function animateElements() {
        gsap.utils.toArray(".animated").forEach((elem, index) => {
            gsap.set(elem.children, {
                opacity: 0
            });

            ScrollTrigger.create({
                trigger: elem,
                start: "top 90%",
                once: true,
                onEnter: () => {
                    gsap.fromTo(elem.children, {
                        y: 50,
                        opacity: 0
                    }, {
                        y: 0,
                        opacity: 1,
                        stagger: 0.1,
                        clearProps: "transform, opacity"
                    });
                },
                onEnterBack: () => {
                    gsap.to(elem.children, {
                        duration: 0.3,
                        opacity: 1,
                        stagger: 0.1
                    });
                }
            });
        });
    }

    function animateElementsRight() {
        gsap.utils.toArray(".animated_right").forEach((elem, index) => {
            gsap.set(elem.children, {
                opacity: 0
            });

            ScrollTrigger.create({
                trigger: elem,
                start: "top 90%",
                once: true,
                onEnter: () => {
                    gsap.fromTo(elem.children, {
                        x: -50,
                        opacity: 0
                    }, {
                        x: 0,
                        opacity: 1,
                        stagger: 0.1,
                        clearProps: "transform, opacity"
                    });
                },
                onEnterBack: () => {
                    gsap.to(elem.children, {
                        duration: 0.3,
                        opacity: 1,
                        stagger: 0.1
                    });
                }
            });
        });
    }

    function animateElementsLeft() {
        gsap.utils.toArray(".animated_left").forEach((elem, index) => {
            gsap.set(elem.children, {
                opacity: 0
            });

            ScrollTrigger.create({
                trigger: elem,
                start: "top 90%",
                once: true,
                onEnter: () => {
                    gsap.fromTo(elem.children, {
                        x: 50,
                        opacity: 0
                    }, {
                        x: 0,
                        opacity: 1,
                        stagger: 0.1,
                        clearProps: "transform, opacity"
                    });
                },
                onEnterBack: () => {
                    gsap.to(elem.children, {
                        duration: 0.3,
                        opacity: 1,
                        stagger: 0.1
                    });
                }
            });
        });
    }

    function animateUnderline() {
        const underlines = gsap.utils.toArray('.underline');

        underlines.forEach((underline) => {
            ScrollTrigger.create({
                trigger: underline,
                start: 'top 90%',
                once: true,
                invalidateOnRefresh: true,
                onEnter: () => {
                    gsap.to(underline, {
                        duration: 2,
                        ease: "power2.out",
                        delay: 0.25,
                        "--underline-width": "35%",
                    });
                },
                onEnterBack: () => {
                    gsap.to(underline, {
                        duration: 2,
                        ease: "power2.out",
                        delay: 0.25,
                        "--underline-width": "35%",
                    });
                }
            });
        });
    }



    function refreshAnimations() {
        ScrollTrigger.refresh();
    }

    function toggleScroll(toggle) {
        if (!smoother) {
            return;
        }
        smoother.paused(toggle);
    }

    function progressTimeline() {
        if (!mobileTl) {
            return;
        }
        mobileTl.progress(1);
    }

    // Initialize animations on load
    initAnimations();

    // Expose functions globally if needed
    window.refreshAnimations = refreshAnimations;
    window.toggleScroll = toggleScroll;
    window.progressTimeline = progressTimeline;

})();